<template lang="">
  <div>
    <b-form-group
      v-if="typePaymentPassword === 'change'"
      class="my-50"
      label-for="swal-input-payment-password-old"
    >
      <template #label>
        <div class="d-flex justify-content-start gap-1">
          {{ $t('myAccount.paymentPassword.oldPassword') }}
          <span
            class="text-danger"
          >(*)</span>
        </div>
      </template>
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          id="swal-input-payment-password-old"
          v-model="oldPassword"
          class="form-control-merge"
          :type="passwordFieldType"
          autocomplete="off"
          :placeholder="$t('myAccount.paymentPassword.placeholder')"
        />
        <b-input-group-append is-text>
          <feather-icon
            class="cursor-pointer"
            :icon="passwordToggleIcon"
            @click="togglePasswordVisibility"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-form-group
      class="my-50"
      label-for="swal-input-payment-password-1"
    >
      <template #label>
        <div class="d-flex justify-content-start gap-1">
          {{ (typePaymentPassword === 'on' || typePaymentPassword === 'change') ? $t('myAccount.paymentPassword.newPassword') : $t('myAccount.paymentPassword.title') }}
          <span
            class="text-danger"
          >(*)</span>
        </div>
      </template>
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          id="swal-input-payment-password-1"
          v-model="password"
          class="form-control-merge"
          :type="passwordFieldType"
          size="lg"
          autocomplete="off"
          :placeholder="(typePaymentPassword === 'on' || typePaymentPassword === 'change') ? $t('myAccount.paymentPassword.newPassword'): $t('myAccount.paymentPassword.placeholder')"
        />
        <b-input-group-append is-text>
          <feather-icon
            class="cursor-pointer"
            :icon="passwordToggleIcon"
            @click="togglePasswordVisibility"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-form-group
      v-if="typePaymentPassword === 'on' || typePaymentPassword === 'change'"
      class="my-50"
      label-for="swal-input-payment-password-2"
    >
      <template #label>
        <div class="d-flex justify-content-start gap-1">
          {{ $t('myAccount.paymentPassword.reNewPasswordPlaceholder') }}
          <span
            class="text-danger"
          >(*)</span>
        </div>
      </template>
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          id="swal-input-payment-password-2"
          v-model="rePassword"
          size="lg"
          class="form-control-merge"
          :type="passwordFieldType"
          autocomplete="off"
          :placeholder="$t('myAccount.paymentPassword.reNewPasswordPlaceholder')"
        />
        <b-input-group-append is-text>
          <feather-icon
            class="cursor-pointer"
            :icon="passwordToggleIcon"
            @click="togglePasswordVisibility"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </div>
</template>
<script>
import {
  BInputGroup, BFormInput, BInputGroupAppend, BFormGroup,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormGroup,
  },
  props: {
    typePaymentPassword: {
      type: String,
      default: 'off',
      require: true,
    },
  },
  setup() {
    const password = ref()
    const rePassword = ref()
    const oldPassword = ref()

    const showPassword = ref(false)
    function togglePasswordVisibility() {
      showPassword.value = !showPassword.value
    }
    const passwordToggleIcon = computed(() => showPassword.value ? 'EyeIcon' : 'EyeOffIcon')
    const passwordFieldType = computed(() => showPassword.value ? 'text' : 'password')

    return {
      showPassword,
      togglePasswordVisibility,
      passwordToggleIcon,
      passwordFieldType,
      password,
      rePassword,
      oldPassword,
    }
  },
}
</script>
<style lang="">

</style>
