var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.typePaymentPassword === 'change' ? _c('b-form-group', {
    staticClass: "my-50",
    attrs: {
      "label-for": "swal-input-payment-password-old"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-start gap-1"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.paymentPassword.oldPassword')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])])];
      },
      proxy: true
    }], null, false, 2216560143)
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    staticClass: "form-control-merge",
    attrs: {
      "id": "swal-input-payment-password-old",
      "type": _vm.passwordFieldType,
      "autocomplete": "off",
      "placeholder": _vm.$t('myAccount.paymentPassword.placeholder')
    },
    model: {
      value: _vm.oldPassword,
      callback: function callback($$v) {
        _vm.oldPassword = $$v;
      },
      expression: "oldPassword"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": _vm.passwordToggleIcon
    },
    on: {
      "click": _vm.togglePasswordVisibility
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-form-group', {
    staticClass: "my-50",
    attrs: {
      "label-for": "swal-input-payment-password-1"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-start gap-1"
        }, [_vm._v(" " + _vm._s(_vm.typePaymentPassword === 'on' || _vm.typePaymentPassword === 'change' ? _vm.$t('myAccount.paymentPassword.newPassword') : _vm.$t('myAccount.paymentPassword.title')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])])];
      },
      proxy: true
    }])
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    staticClass: "form-control-merge",
    attrs: {
      "id": "swal-input-payment-password-1",
      "type": _vm.passwordFieldType,
      "size": "lg",
      "autocomplete": "off",
      "placeholder": _vm.typePaymentPassword === 'on' || _vm.typePaymentPassword === 'change' ? _vm.$t('myAccount.paymentPassword.newPassword') : _vm.$t('myAccount.paymentPassword.placeholder')
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": _vm.passwordToggleIcon
    },
    on: {
      "click": _vm.togglePasswordVisibility
    }
  })], 1)], 1)], 1), _vm.typePaymentPassword === 'on' || _vm.typePaymentPassword === 'change' ? _c('b-form-group', {
    staticClass: "my-50",
    attrs: {
      "label-for": "swal-input-payment-password-2"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-start gap-1"
        }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.paymentPassword.reNewPasswordPlaceholder')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])])];
      },
      proxy: true
    }], null, false, 1123721600)
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    staticClass: "form-control-merge",
    attrs: {
      "id": "swal-input-payment-password-2",
      "size": "lg",
      "type": _vm.passwordFieldType,
      "autocomplete": "off",
      "placeholder": _vm.$t('myAccount.paymentPassword.reNewPasswordPlaceholder')
    },
    model: {
      value: _vm.rePassword,
      callback: function callback($$v) {
        _vm.rePassword = $$v;
      },
      expression: "rePassword"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": _vm.passwordToggleIcon
    },
    on: {
      "click": _vm.togglePasswordVisibility
    }
  })], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }